<template>
  <v-card class="elevation-5 mx-auto mt-15 rounded-lg" :loading="loading" width="450">
    <v-card-title class="title primary--text"> Change Password </v-card-title>
    <v-card-text class="mt-5 pa-10">
      <v-form>
        <v-text-field label="Old password" name="old_password" :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showOldPassword ? 'text' : 'password'" outlined dense v-model="form.old_password" prepend-icon="mdi-lock"
          @click:append="showOldPassword = !showOldPassword" :error="errors.old_password != null"
          :error-messages="errors.old_password"></v-text-field>
        <v-text-field label="New password" name="password" :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showNewPassword ? 'text' : 'password'" outlined dense v-model="form.password" prepend-icon="mdi-lock"
          @click:append="showNewPassword = !showNewPassword" :error="errors.password != null"
          :error-messages="errors.password"></v-text-field>
        <v-text-field label="Confirm Password" name="password_confirmation" prepend-icon="mdi-lock"
          :type="showConfirmPassword ? 'text' : 'password'" :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
          outlined dense v-model="form.password_confirmation" :error="errors.password_confirmation != null"
          @click:append="showConfirmPassword = !showConfirmPassword"
          :error-messages="errors.password_confirmation"></v-text-field>
      </v-form>
      <v-btn color="info" class="ml-3 px-4 mt-5" @click="onSubmit" v-bind:disabled="loading">Update</v-btn>
    </v-card-text>
    <v-snackbar v-model="snackbar" :timeout="3000" color="red accent-4" align-baseline="center">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  data: function () {
    return {
      loading: false,
      showNewPassword: false,
      showOldPassword: false,
      showConfirmPassword: false,
      form: {
        old_password: "",
        password: "",
        password_confirmation: "",
      },
      errors: {},
      message: "",
      snackbar: false,
      token: null,
    };
  },
  methods: {
    onSubmit(event) {
      let _self = this;
      event.preventDefault();

      _self.loading = true;

      this.$axios
        .post("admin/changePassword", _self.form)
        .then((res) => {
          let responsedata = res.data;
          _self.loading = false;

          if (!responsedata.success) {
            _self.errors = {};
            if (responsedata.errors) {
              for (let i = 0; i < responsedata.errors.length; i++) {
                _self.errors[responsedata.errors[i].field] =
                  responsedata.errors[i].message;
              }
            } else {
              _self.snackbar = true;
              _self.message = responsedata.message;
            }
          } else {
            _self.$router.push("/home");
          }
        });
    },
  },
  mounted() {
    this.token = JSON.parse(localStorage.getItem("user")).token;
  },
};
</script>

<style scoped>
.title {
  background-color: rgba(25, 118, 210, 0.1) !important;
  justify-content: center;
}
</style>